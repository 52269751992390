export default {
  language: navigator.language.split('-')[0],
  companyData: {
    returnOffers: [],
    returnReasons: [],
    returnSteps: [],
    returnablePeriod: 30,
  },
  isLoading: false,
  errorMessage: '',
};
